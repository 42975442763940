import { gsap } from 'gsap';

export const revealHeader = () => {
  let tl = gsap.timeline();

  tl.fromTo(
    '.produktkatalog-page__header__headline',
    { y: 50, autoAlpha: 0 },
    { y: 0, autoAlpha: 1, duration: 0.6, ease: 'power1.out' }
  )
    .fromTo(
      '.produktkatalog-page__header__image',
      { autoAlpha: 0, y: 100 },
      { autoAlpha: 1, y: 0, duration: 1.2, ease: 'power4.out' }
    )
    .fromTo(
      '.produktkatalog-page__header__information-group',
      { autoAlpha: 0, y: 100 },
      { autoAlpha: 1, y: 0, duration: 1.2, ease: 'power4.out' },
      '-=0.6'
    );

  return () => {
    tl.pause(0).kill(true);
  };
};
