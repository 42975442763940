import React from 'react';

// External Components

// External Components
import { Heading, Box, Paragraph, GridWrapper } from '@thepuzzlers/pieces';

// Local Components
import {
  Hyperlink,
  ShortInformationBlock,
  ImageWithLinearGradient
} from 'components';

// Utils
import { convertStringToSlug } from 'utils';

export const CategoryCard = ({
  image,
  name,
  shortDescription,
  shortInformations,
  buttonLabel,
  isOdd,
  index
}) => (
  <GridWrapper
    className="house-categories__category-card"
    variant="inside.autoColumns"
    sx={{
      gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '1/25'],
      mt:
        index === 0
          ? ['10rem', '10.3rem', '10rem', '7.7rem', '10rem', '11.9rem']
          : ['15rem', '27.6rem', '14.8rem', '14rem', '16.7rem', '19.2rem']
    }}>
    <CardImage
      {...image}
      name={name}
      sx={{ gridColumnStart: !isOdd ? 1 : [1, 1, 2, 2, 11, 13] }}
    />
    <CardShortInformation
      data={shortInformations}
      sx={{
        gridColumnStart: !isOdd ? [1, 1, 1, 1, 16, 14] : [1, 1, 2, 2, 1, 2]
      }}
    />
    <CardDescription
      data={shortDescription}
      sx={{
        gridColumnStart: !isOdd ? [1, 1, 1, 1, 16, 14] : [1, 1, 2, 2, 1, 2]
      }}
    />
    <CardButton
      name={name}
      text={buttonLabel}
      sx={{
        gridColumnStart: !isOdd ? [1, 1, 23, 23, 16, 14] : [1, 1, 24, 24, 1, 2]
      }}
    />
  </GridWrapper>
);

const CardImage = ({ name, image, alt, sx }) => (
  <Box
    sx={{
      display: 'grid',
      gridColumnEnd: [13, 13, 'span 23', 'span 23', 'span 14', 'span 12'],
      gridRow: [1, 1, 1, 1, '1/span 4', '1/span 4'],
      ...sx
    }}>
    <ImageWithLinearGradient
      src={image}
      alt={alt}
      sx={{
        gridArea: '1/1',
        height: [
          '31.9rem',
          '51.8rem',
          '31.2rem',
          '30.4rem',
          '38.6rem',
          '42.1rem'
        ]
      }}
      isToBottom
    />
    <Heading
      as="h3"
      sx={{
        color: 'white',
        gridArea: '1/1',
        fontFamily: 'primary.normal',
        fontFeatureSettings:
          "'salt' on, 'ss01' on, 'ss02' on, 'ss03' on, 'ss04' on, 'ss05' on, 'ss08' on, 'ss14' on",
        fontSize: ['2.2rem', '3.2rem', '2.4rem', '2.4rem', '2.4rem', '3.2rem'],
        lineHeight: 1,
        m: [
          '2.4rem 1.2rem 0',
          '2.4rem 1.9rem 0',
          '2.4rem 3.2rem 0',
          '2.4rem 2.4rem 0',
          '3rem 2.1rem 0',
          '3.2rem 3.2rem 0'
        ],
        zIndex: 1
      }}
      dangerouslySetInnerHTML={{ __html: name }}
    />
  </Box>
);

const CardShortInformation = ({ data, sx }) => (
  <Box
    sx={{
      display: 'grid',
      gap: [
        '1.9rem 5.9rem',
        '4rem 5.4rem',
        '4.2rem',
        '3.7rem',
        '4.5rem 5.1rem',
        '3.2rem 7rem'
      ],
      gridColumnEnd: [13, 'span 11', 'span 23', 'span 23', 'span 8', 'span 9'],
      gridTemplateColumns: [
        '1fr 1fr',
        '1fr 1fr',
        'repeat(4, 1fr)',
        'repeat(4, 1fr)',
        '1fr 1fr',
        '1fr 1fr'
      ],
      gridRow: [2, 2, 2, 2, 1, 1],
      mt: ['3.2rem', '4rem', '2.4rem', '5.2rem', 0, 0],
      ...sx
    }}>
    {data.map((item) => (
      <ShortInformationBlock
        key={item.label}
        label={item.label}
        text={item.text}
        animatedLine
      />
    ))}
  </Box>
);

const CardDescription = ({ data, sx }) => (
  <Paragraph
    dangerouslySetInnerHTML={{
      __html: data
    }}
    sx={{
      fontFamily: 'body.normal',
      fontSize: ['1.5rem', '2rem', '1.5rem', '1.5rem', '1.4rem', '1.5rem'],
      gridColumnEnd: [13, 'span 11', 'span 14', 'span 13', 'span 9', 'span 9'],
      gridRow: 3,
      lineHeight: 1.5,
      mt: ['3.2rem', '4.8rem', '4.4rem', '4rem', 0, 0],
      '& > span': {
        fontFamily: 'body.bold'
      },
      ...sx
    }}
  />
);

const CardButton = ({ text, name, sx }) => (
  <Hyperlink
    to={`/modulhaeuser/${convertStringToSlug(name.replace('<br/>', ''))}/`}
    text={text}
    sx={{
      alignSelf: 'center',
      gridRow: [4, 4, 3, 3, 4, 4],
      justifySelf: ['start', 'start', 'end', 'end', 'start', 'start'],
      mt: ['5.6rem', '4.8rem', '4.4rem', '4rem', '3.8rem', '2.4rem'],
      ...sx
    }}
  />
);
