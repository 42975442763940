import React from 'react';

// External Components
import { Section } from '@thepuzzlers/pieces';

// Local Components
import { Spacer } from 'components';

// Utils
import {
  formatSquareMeterWithSuperscript,
  convertStringToSlug,
  getPlanPrice
} from 'utils';
import { convertApiHtmlText } from 'graphqlHooks/helper';
import {
  CategoryCard,
  CategoryDescription,
  CategoryHeadline
} from './components';

export const HouseCategories = ({ data, labels }) => {
  return (
    // Markup
    <>
      {data.map((category, index) => {
        return (
          <React.Fragment key={category.title}>
            {index !== 0 && <SectionSpacer />}
            <Section
              id={`house-category__${convertStringToSlug(category.title)}`}>
              <CategoryHeadline data={category.title} />
              <CategoryDescription
                data={formatSquareMeterWithSuperscript(
                  convertApiHtmlText(category.description.html)
                )}
              />
              <Cards data={category.house} labels={labels} />
            </Section>
          </React.Fragment>
        );
      })}
    </>
  );
};

// Elements

const Cards = ({ data, labels }) =>
  data.map((houseItem, index) => {
    const isOdd = index % 2 !== 0;

    const {
      name,
      shortName,
      short_description,
      living_area,
      footprint,
      price,
      bedrooms,
      image
    } = houseItem.data || houseItem;

    const shortInformations = [
      {
        label: labels.living_space_label,
        text: formatSquareMeterWithSuperscript(living_area)
      },
      {
        label: labels.floor_space_label,
        text: formatSquareMeterWithSuperscript(footprint)
      },
      {
        label: labels.price_label,
        text: getPlanPrice(price.replace(labels.price_label, ''))
      },
      {
        label: labels.bedroom_label,
        text: bedrooms
      }
    ];

    return (
      <CategoryCard
        key={name}
        name={shortName || name}
        image={image}
        shortDescription={convertApiHtmlText(short_description.html)}
        shortInformations={shortInformations}
        buttonLabel={labels.button_label}
        isOdd={isOdd}
        index={index}
      />
    );
  });

const SectionSpacer = () => (
  <Spacer
    height={['16.4rem', '32rem', '24rem', '20rem', '31.3rem', '33.3rem']}
  />
);
