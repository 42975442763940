import React from 'react';

// External Components
import { Paragraph } from '@thepuzzlers/pieces';

export const CategoryDescription = ({ data }) => (
  <Paragraph
    dangerouslySetInnerHTML={{
      __html: data
    }}
    sx={{
      fontFamily: 'body.normal',
      fontSize: ['1.5rem', '1.8rem', '1.5rem', '1.5rem', '1.5rem', '1.5rem'],
      gridColumn: [
        '1/13',
        '1/13',
        '4/span 18',
        '5/span 16',
        '4/span 18',
        '6/span 14'
      ],
      lineHeight: 1.5,
      mt: ['3.2rem', '3.2rem', '2.4rem', '3.2rem', '2.2rem', '2.3rem'],
      textAlign: 'center',
      '& > span': {
        fontFamily: 'body.bold'
      }
    }}
  />
);
