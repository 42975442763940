import { useStaticQuery, graphql } from 'gatsby';

export const useProduktkatalogPageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: produktkatalogPageDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
      }
    }
  `);

  return data;
};
