import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';
import { Helmet } from 'react-helmet';

// Local Components
import { Spacer } from 'components';

// Self Components
import {
  useProduktkatalogPageData,
  Header,
  HouseCategories
} from 'sections/produktkatalogPage';

// Assets
import thumbnail from 'assets/seo/thumbnail.png';

// Cms Data
import {
  useKisProduktkatalogPageData,
  useKisHouseCategoriesData
} from 'graphqlHooks';
import { useFloorPlansData } from 'graphqlHooks/useFloorPlansData';

const Produktkatalog = () => {
  const { seo } = useProduktkatalogPageData();
  const { header, house_categories: houseCategoriesLabels } =
    useKisProduktkatalogPageData();
  const houseCategories = useKisHouseCategoriesData();

  const multiplePlansHouseCategories = useFloorPlansData();

  const categoryToBeDisplayed = [
    ...houseCategories,
    ...multiplePlansHouseCategories
  ];

  return (
    <>
      <SEO {...seo} />
      <Helmet>
        {/* Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seo.url} />
        <meta property="og:image" content={thumbnail} />

        {/* Twitter */}
        <meta property="twitter:url" content={seo.url} />
        <meta property="twitter:image" content={thumbnail} />
      </Helmet>
      <Spacer
        height={['2rem', '5.6rem', '3.1rem', '4.1rem', '7.7rem', '6.6rem']}
      />
      <Header data={header} />
      <Spacer
        height={['14.5rem', '24rem', '24rem', '24.5rem', '21.4rem', '19.1rem']}
      />
      <HouseCategories
        data={categoryToBeDisplayed}
        labels={houseCategoriesLabels}
      />
      <Spacer
        height={['20rem', '42rem', '37.3rem', '19rem', '44rem', '44rem']}
      />
    </>
  );
};

export default injectIntl(Produktkatalog);
