import React from 'react';
//  External Components
import { Heading } from '@thepuzzlers/pieces';

// utils
import { formatSquareMeterWithSuperscript } from 'utils';

export const CategoryHeadline = ({ data }) => (
  <Heading
    as="h2"
    sx={{
      fontSize: ['2.8rem', '4rem', '3.6rem', '3.2rem', '4rem', '4.8rem'],
      fontFamily: 'primary.bold',
      fontFeatureSettings:
        "'salt' on, 'ss01' on, 'ss02' on, 'ss03' on, 'ss04' on, 'ss05' on, 'ss08' on",
      gridColumn: [
        '1/13',
        '1/13',
        '2/span 22',
        '3/span 20',
        '4/span 18',
        '4/span 18'
      ],
      lineHeight: 1.2,
      textAlign: 'center',
      position: 'relative'
    }}
    dangerouslySetInnerHTML={{
      __html: formatSquareMeterWithSuperscript(data)
    }}
  />
);
