import React from 'react';

// External Components
import { Section, Heading, Box } from '@thepuzzlers/pieces';

// Local Components
import {
  GridSpacer,
  ImageContainer,
  ImageWithLinearGradient,
  LinearGradient,
  ShortInformationBlock
} from 'components';
import { formatSquareMeterWithSuperscript } from 'utils';

// Animations
import { revealHeader } from './animations';

export const Header = ({
  data: { title, subtitle, image, short_information }
}) => {
  React.useEffect(() => {
    const killAnimation = revealHeader();

    return killAnimation;
  }, []);

  return (
    // Markup
    <Section as="header" id="produktkatalog-page__header">
      <Headline title={title} subtitle={subtitle} />
      <Spacer />
      <ShortInformationGroup data={short_information} />
      <Image {...image} />
    </Section>
  );
};

// Elements

const Headline = ({ title, subtitle }) => {
  const styles = {
    display: 'initial',
    fontSize: ['1.8rem', '2.2rem', '2rem', '1.8rem', '2.8rem', '3.2rem'],
    fontFeatureSettings:
      "'salt' on, 'ss01' on, 'ss02' on, 'ss03' on, 'ss04' on, 'ss05' on, 'ss08' on, 'calt' off",
    lineHeight: 1.5
  };

  return (
    <Box
      className="produktkatalog-page__header__headline"
      sx={{
        display: 'inline-block',
        gridColumn: [
          '1/13',
          '1/13',
          '1/span 20',
          '1/span 21',
          '1/span 21',
          '1/span 19'
        ],
        gridRow: [3, 3, 3, 1, 1, 1],
        // Animation value
        visibility: 'hidden'
      }}>
      <Heading as="h1" sx={{ fontFamily: 'primary.bold', ...styles }}>
        {title}
      </Heading>
      <Heading
        as="h2"
        sx={{ fontFamily: 'primary.normal', ml: '0.6rem', ...styles }}>
        {subtitle}
      </Heading>
    </Box>
  );
};

const Image = ({ image, alt }) => (
  <ImageWithLinearGradient
    className="produktkatalog-page__header__image"
    src={image}
    alt={alt}
    sx={{
      gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '1/25'],
      gridRow: [1, 1, 1, 3, 3, 3],
      height: ['40rem', '51.8rem', '41.6rem', '40.4rem', '58.8rem', '55.9rem'],
      // Animation value
      visibility: 'hidden'
    }}
    imgStyle={{
      objectPosition: 'center bottom'
    }}
    imgProps={{
      loading: 'eager'
    }}
    isToBottom
  />
);

const ShortInformationGroup = ({ data }) => (
  <Box
    className="produktkatalog-page__header__information-group"
    sx={{
      alignSelf: 'start',
      display: 'grid',
      gap: ['3rem 5.9rem', '3.6rem 5.4rem', '4.2rem', '4.2rem', '5rem', '5rem'],
      gridColumn: [
        '2/span 10',
        '2/span 9',
        '2/span 14',
        '2/span 15',
        '1/span 14',
        '1/span 11'
      ],
      gridRow: [1, 1, 1, 3, 3, 3],
      gridTemplateColumns: [
        '1fr 1fr',
        '1fr 1fr',
        'repeat(3, 1fr)',
        'repeat(3, 1fr)',
        'repeat(3, 1fr)',
        'repeat(3, 1fr)'
      ],
      mt: ['3.8rem', '4.6rem', '3rem', '3rem', '3rem', '3rem'],
      pl: [0, 0, 0, 0, '3rem', '3rem'],
      zIndex: 1,
      // Animation value
      visibility: 'hidden'
    }}>
    {data.map((item) => (
      <ShortInformationBlock
        key={item.label}
        label={item.label}
        text={formatSquareMeterWithSuperscript(item.text)}
        isLight
      />
    ))}
  </Box>
);

const Spacer = () => (
  <GridSpacer
    sx={{
      gridRow: 2,
      height: ['4.5rem', '2.9rem', '3.2rem', '3.2rem', '6.4rem', '7.5rem']
    }}
  />
);
