import { useStaticQuery, graphql } from 'gatsby';

export const useFloorPlansData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allFloorPlanDataJson {
        nodes {
          title
          description {
            html
          }
          house {
            bedrooms
            thumbnail_image {
              alt
              image {
                publicURL
              }
            }
            short_description {
              html
            }
            production_time
            price
            shortName
            name {
              html
            }
            long_description {
              html
            }
            living_area
            footprint
            floor_plans {
              width
              rooms {
                name
                size
              }
              name
              price
              modules
              living_area
              levels {
                name
                rooms {
                  name
                  size
                }
                image {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                }
              }
              length
              image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
              footprint
            }
            image {
              alt
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  `);

  return data.nodes;
};
